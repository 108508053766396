import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { useLocalization } from "gatsby-theme-i18n";
import Container from "../../../components/Container";
import NewLayout from "../../../components/NewLayout";
import InterestedItems from "../../../components/InterestedItems";
import { headers } from "../../../utils/navbar";
import financialInformationDocs from "../../../cms/financialInformationDocs/financialInformationDocs";
import DocumentList from "../../../components/DocumentList";
import Tabs from "../../../components/Tabs";

function InvestorsBankReportsPage() {
  const { locale } = useLocalization();
  const path = "attributes.publish_year";
  const annualBankingDocs = financialInformationDocs[locale].filter(({ attributes: { category } }) => category === "Annual_Banking_Reports");
  const annualFinancialDocs = financialInformationDocs[locale].filter(({ attributes: { category } }) => category === "Annual_Financial_Reports");
  const afbBankingGroup = _.sortBy(financialInformationDocs[locale].filter(({ attributes: { category } }) => category === "Pilar_Disclosures_Banking_Group"), "attributes.title");
  const afbBankConsolidated = _.sortBy(financialInformationDocs[locale].filter(({ attributes: { category } }) => category === "Pilar_Disclosures_Consolidated"), "attributes.title");
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(headers(), "investors.elements.share_information") },
    { ..._.get(headers(), "investors.elements.financial_information_reports.elements.financial_calendar") },
  ];
  const content = {
    financial: _.groupBy(annualFinancialDocs, path),
    banking: _.groupBy(annualBankingDocs, path),
    disclosures_group: _.groupBy(afbBankingGroup, path),
    disclosures_consolidated: _.groupBy(afbBankConsolidated, path),
  };
  const currentYears = _.reverse(_.uniq(Object.values(content).map((item) => Object.keys(item)).flat()).sort());
  const [selectedYear, setSelectedYear] = React.useState(currentYears[0]);

  return (
    <NewLayout
      byDefault
      apps="investors.financial_information_reports.bank_reports"
    >
      <Container pb={8}>
        <Typography mt={3} variant="h2">
          Allfunds Bank Financial Information And Reports
        </Typography>
        <Typography mt={2}>
          This section contains the annual reports, audit reports and Pillar
          III disclosures of Allfunds Bank, S.A.U. and Liberty Partners,
          S.L.U.
        </Typography>
        <Box mt={{ xs: 3, sm: 7 }}>
          <Tabs
            value={selectedYear}
            tabs={currentYears}
            onChange={(_, value) => setSelectedYear(value)}
          />
          <Box mt={{ xs: 2, sm: 4 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <DocumentList
                  title="Annual Financial Reports"
                  description="
                      Information prepared in compliance with article 87 of Law
                      10/2014, of 26 June on the regulation, supervision and
                      solvency of credit institutions.
                  "
                  items={_.get(content, `financial.${selectedYear}`, [])}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DocumentList
                  title="Annual Banking Reports"
                  description="
                      Information prepared in compliance with article 87 of Law
                      10/2014, of 26 June on the regulation, supervision and
                      solvency of credit institutions.
                  "
                  items={_.get(content, `banking.${selectedYear}`, [])}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={{ xs: 4, sm: 8 }}>
            <Typography variant="h3">
              Pillar III Disclosures
            </Typography>
            <Typography my={2} fontSize="16px">
              Pursuant to the Basel III framework, as implemented in Europe
              via Directive 2013/36 and Regulation 575/2013 on prudential
              requirements for credit institutions and investment firms, we
              present this Pillar III disclosures report with the market
              disclosure and reporting requirements set forth in section VIII
              of said Regulation
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <DocumentList
                  title="AFB Banking Group"
                  description="
                      Allfunds Bank, S.A.U. and its subsidiaries, together with
                      Liberty Partners, S.L.U., which is the EU Parent Financial
                      Holding Company of the AFB Banking Group.
                  "
                  items={_.get(
                    content,
                    `disclosures_group.${selectedYear}`,
                    [],
                  )}
                  isSmall
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DocumentList
                  title="Allfunds Bank (Consolidated)"
                  description="Allfunds Bank, S.A.U. and each of its subsidiaries."
                  items={_.get(
                    content,
                    `disclosures_consolidated.${selectedYear}`,
                    [],
                  )}
                  isSmall
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  Payment to Suppliers
                </Typography>
                <Typography my={2} fontSize="16px">
                  The average payment period to suppliers of Allfunds Bank,
                  S.A.U. was below the legal maximum of 30 days established in
                  Spain by law 3/2004, as of 29 December 2004, on combating
                  late payments in commercial transactions. The average
                  payment period was calculated in accordance with the
                  applicable provisions of the aforementioned law.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

export default InvestorsBankReportsPage;
